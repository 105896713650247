export const HR_HR = {
  MAIN_TITLE: 'Teorem',
  WATERMARK: '© Teorem',
  BIRTH_DATE_FORMAT: 'DD/MM/YYYY',
  DATE_FORMAT: 'DD. MMM YYYY',
  ERROR_HANDLING: {
    UNHANDLED_ERROR: 'Došlo je do neočekivane pogreške!',
    SUPPORT: 'Nešto je pošlo po zlu, molimo Vas kontaktirajte podršku',
    CREDIT_CARD_MISSING: 'Nedostaju podatci kartice. Molimo dodajte način plaćanja prije kreiranja rezervacije.',
    DEFAULT_CARD_MISSING: 'Molimo odredite jednu od svojih kartica kao standardni način plaćanja.',
    NO_CHILD_USERNAME: 'Ne postoji dijete s danim korisničkim imenom',
    REGISTERED_SUCCESSFULLY: 'Registracija uspješna.',
  },
  TRIAL_POPUP: {
    WELCOME: 'Welcome to Teorem',
    DESCRIPTION: 'Your profile is now ready to use. Fill out the agenda, sit back and relax, wait for students to reach out to you!',
    EXPIRATION: 'Your free trial will expire in 30 days!',
    REMIND: 'Don’t worry, we’ll remind you on time.',
    CONFIRM_BUTTON: 'Got it',
  },
  EMAIL_CONFIRMATION_POPUP: {
    WELCOME: 'Dobrodošli na Teorem!',
    DESCRIPTION: 'Molimo provjerite svoj sandučiċ i potvrdite Vašu e-mail adresu klikom na link koji smo Vam poslali.',
    EXPIRATION: 'Ponovno pošalji email za potvrdu',
    CONFIRM_BUTTON: 'U redu',
    RESEND_TIMEOUT: 'Probajte ponovno za',
  },
  ONBOARDING: {
    COUNTRY_SELECT: 'Odaberite svoje tržište: ',
    NAVIGATION: {
      TITLE: 'Dobrodošli na Teorem!',
      DESCRIPTION: 'Molimo Vas pratite proces kako bi završili izradu profila. Proces traje samo nekoliko minuta.',
      STEP_ONE: {
        TITLE: 'Osobni podatci',
        DESCRIPTION: 'Dopustite nam da Vas upoznamo malo bolje',
      },
      TUTOR: {
        STEP_TWO: {
          TITLE: 'Podatci za plaćanje',
          DESCRIPTION: 'Nikada nije previše podataka',
        },
      },
      PARENT: {
        STEP_TWO: {
          TITLE: 'Podatci o djeci',
          DESCRIPTION: 'Ispunite podatke za Vaše dijete i odredite njegove/njene podatke za prijavu.',
        },
      },
    },
    EDIT_CHILD_DETAILS: 'Izmijenite podatke o djetetu',
  },
  TUTOR_ONBOARDING: {
    TITLE: 'Hvala što ste potvrdili svoju adresu e-pošte! Dovršimo vaš profil!',
    SUBTITLE: 'Ovo obično traje 8 minuta.',
    IMAGE_NOTE: 'Za sada možete preskočiti, ali morat ćete dodati profilnu sliku prije nego što počnete podučavati.',
    ABOUT_ME_TIPS: {
      TIP_1: 'Detaljno opišite svoj način predavanja i sebe.',
      TIP_2: 'Najučinkovitiji profili sadrže 100+ riječi u rubirkama <u><i>više o meni</i></u> i <u><i>više o mojim predavanjima</i></u>.',
    },
    TOOLTIPS: {
      AVAILABILITY_1: 'Odaberite kada ste dostupni za instrukcije, po dijelovima dana u tjednu.',
      AVAILABILITY_2: 'Svoju dostupnost možete kasnije izmijeniti u bilo kojem trenutku.',
      SUBJECTS: 'Kasnije možete dodati ili obrisati predmete u postavkama Vašeg profila.',
      SUBJECTS_TIP_1: 'Dodajte predmete koje želite podučavati i odredite svoju cijenu.',
      SUBJECTS_TIP_2: 'Imajte na umu da Teorem uzima proviziju od 15%.',
    },
    IMAGE_TIPS: {
      TITLE: 'Naputci za fotografiju',
      TIP_1: 'portret oblik',
      TIP_2: 'ozbiljnog stava s osmjehom',
      TIP_3: 'visoke kvalitete (min 500x500 piksela)',
    },
    PAYOUTS_BUTTON: {
      PRIVATE: 'Privatna osoba',
      COMPANY: 'Tvrtka',
    },
  },
  FORM_VALIDATION: {
    INVALID_EMAIL: 'Neispravan email',
    INVALID_IMAGE: 'Slika mora biti formata jpg, png, jpeg ili svg i manja od 2MB veličine.',
    IMAGE_TYPE: 'Slika mora biti formata jpg, png, jpeg ili svg',
    IMAGE_SIZE: 'Slika mora biti manja od 5MB veličine.',
    TOO_SHORT: 'Pretratko!',
    TOO_LONG: 'Predugo!',
    MAX_LIMIT: 'Maksimalni broj slova je',
    MIN_LIMIT: 'Minimalni broj riječi je',
    TOO_BIG: 'Broj je prevelik',
    REQUIRED: 'Neophodno polje',
    LOWERCASE: 'Jedno malo slovo (a-z)',
    UPPERCASE: 'Jedno veliko slovo (A-Z)',
    NUMBER: 'Jednu brojku (0-9)',
    MIN_CHARACTERS: '8 znakova',
    SPECIAL_CHAR: 'Jedan poseban znak (@, $, !, %, *, ?, &...)',
    PASSWORD_MUST: 'Lozinka mora sadržavati barem',
    PASSWORD_MATCH: 'Lozinke se moraju poklapati',
    PASSWORD_STRENGTH: 'Lozinka mora ispunjavati uvjete',
    PRICE: 'Minimalno ',
    WHOLE_NUMBER: 'Nije cijeli broj',
    FUTURE_DATE: 'Datum ne može biti u budućnosti',
    VALID_DATE: 'Datum je nevažeći',
    PHONE_NUMBER: 'Neispravan broj telefona',
    MINIMUM_AGE: 'Morate imati više od 18 godina',
    MAXIMUM_AGE: 'Morate imati manje od 100 godina',
    TUTOR_AGE: 'Instruktor mora imati više od 18 godina',
    WRONG_REQUIREMENTS: 'Niste ispunili sva potrebna polja',
    NEGATIVE: 'Ne može biti negativan broj',
    USERNAME_EXIST: 'Korisničko ime već postoji',
    TOAST: {
      UPDATE: 'Uspješno ste ažurirali podatke o djetetu',
      CREATE: 'Uspješno ste dodali dijete',
      DELETE: 'Uspješno ste uklonili dijete',
    },
    LEVEL_REQUIRED: 'Razina je obvezna',
    SUBJECT_REQUIRED: 'Predmet je obvezan',
    CHILD_REQUIRED: 'Dijete je obvezno',
    AGREE_TERMS_REQUIRED: 'Morate prihvatiti odredbe i uvjete',
    CHILDLESS_CALENDAR_1: 'Dodajte dijete u',
    CHILDLESS_CALENDAR_2: 'postavkama profila',
    INVALID_IBAN: 'Neispravan IBAN',
    IBAN_WHITESPACES: 'IBAN ne smije sadržavati prazne znakove',
    IBAN_MATCH: 'IBANi se moraju podudarati',
    MIN_50_WORDS: 'Najmanje 50 riječi',
    MAX_2500_CHARS: 'Najviše 2500 znakova',
    MAX_75_CHARS: 'Najviše 75 znakova',
    MAX_100_CHARS: 'Najviše 100 znakova',
    MIN_100_CHARS: 'Najmanje 10 znakova',
    NAME_EMAIL_NOT_IN_PASSWORD: 'Ne sadržava ni ime ni email adresu',
  },
  NAVIGATION: {
    MY_BOOKINGS: 'Kalendar',
    MY_REVIEWS: 'Moje recenzije',
    SEARCH_TUTORS: 'Pretraga instruktora',
    TUTOR_MANAGMENT: 'Upravljanje instruktorima',
    STUDENT_MANAGEMENT: 'Upravljanje studentima',
    BOOKING_MANAGEMENT: 'Upravljanje rezervacijama',
    TUTOR_VIDEOS: 'Preview videi',
    MY_PROFILE: 'Moj profil',
    COMPLETED_LESSONS: 'Završene lekcije',
    CHAT: 'Razgovori',
    DASHBOARD: 'Upravljačka ploča',
    EARNINGS: 'Prihod',
    SHARE_PROFILE: 'Podijeli profil',
    TEXT_COPIED: 'Profil kopiran!',
    LOGIN: 'Prijava',
    REGISTER: 'Registracija',
  },
  SEARCH_TUTORS: {
    AVAILABILITY: {
      TIME_OF_DAY: {
        LABEL: 'Vrijeme dana',
        BEFORE_NOON: 'Prije 12:00',
        NOON_TO_FIVE: '12:00 - 17:00',
        AFTER_FIVE: 'Nakon 17:00',
      },
      DAY_OF_WEEK: {
        LABEL: 'Dan u tjednu',
        MON: 'pon',
        TUE: 'uto',
        WED: 'sri',
        THU: 'čet',
        FRI: 'pet',
        SAT: 'sub',
        SUN: 'ned',
      },
    },
    PLACEHOLDER: {
      LEVEL: 'Razina',
      SUBJECT: 'Predmet',
      AVAILABILITY: 'Dostupnost',
    },
    NO_OPTIONS_MESSAGE: 'Molimo prvo odaberite razinu',
    TITLE: 'Pretraga instruktora',
    VIEW_PROFILE: 'Pogledaj Profil',
    BOOK_LESSON: 'Rezerviraj',
    RESET_FILTER: 'Resetiraj pretragu',
    TUTOR_AVAILABLE: 'Dostupni instruktori',
    COMPLETED_LESSONS: 'završenih lekcija',
    NO_REVIEWS: 'Još nema recenzija',
    NO_COMPLETED_LESSONS: 'Još nema završenih lekcija',
    NO_VIDEO: 'Instruktor još nije učitao video',
    SUBJECT_LIST: {
      MORE: 'više',
    },
    NO_RESULT: {
      TITLE: 'Bez rezultata',
      DESC: ' Hmm, izgleda da nema instruktora za ovu određenu pretragu.',
    },
    NOT_FILLED: 'Ovi podatci još nisu ispunjeni',
    YEARS_OF_EXPERIENCE: 'Godine iskustva: ',
    NUMBER_OF_REVIEWS: 'ocjena',
    TUTOR_PROFILE: {
      ABOUT_ME: 'O meni',
      ABOUT_TEACHINGS: 'O mojim predavanjima',
      EMPTY_STATE_ABOUT: 'Ovaj instruktor nije ispunio odjeljak "O meni"',
      EMPTY_STATE_LESSON: 'Ovaj instruktor nije ispunio odjeljak "O predmetima"',
      ADDITIONAL_INFORMATION_TITLE: 'O meni',
      ADDITIONAL_INFORMATION_DESC: 'Izmjena i ažuriranje sekcije "O meni" Vašeg profila.',
      UPDATE_ADDITIONAL_INFO_SUCCESS: 'Dodatni podatci ažurirani!',
      UPDATE_TEACHINGS_SUCCESS: 'Predmeti ažurirani!',
      LESSON_LENGTH: '50 min predavanje',
      FORM: {
        ABOUT_TUTOR_LABEL: 'Recite nam više o sebi*',
        ABOUT_TUTOR_PLACEHOLDER: 'Što Vas najbolje opisuje, koji su Vaši hobiji, pristup...',
        ABOUT_TUTOR_REQUEST: 'Najmanje 50 riječi',
        ABOUT_TUTOR_TOOLTIP: 'Detaljno opišite sebe, svoje interese, iskustvo i hobije.',
        TOOLTIP_EFFECTIVE: 'Najučinkovitiji profili sadrže 100+ riječi u ovoj rubrici.',
        ABOUT_LESSONS_LABEL: 'Recite nam više o Vašim predavanjima*',
        ABOUT_LESSONS_PLACEHOLDER: 'Opišite Vaša predavanja, pristup učenju...',
        ABOUT_LESSONS_TOOLTIP: 'Detaljno opišite na koji način radite s učenicima.',
        ABOUT_LESSONS_TOOLTIP_2: 'Opišite koje metode podučavanja koristite (vizualne, auditivne, kinestetičke, itd.)',
        SUBMIT_BTN: 'Spremanje',
      },
      NO_PRICE: 'Cijena nije navedena',
      HOUR: 'sat',
    },
    PRICE_SORT: 'Sortirati po',
    SORT_LOW: 'Najnižoj cijeni',
    SORT_HIGH: 'Najvišoj cijeni',
    SORT_NONE: 'Bez sortiranja',
    NEW_TUTOR: {
      PART_1: 'Novi',
      PART_2: 'instruktor',
    },
  },
  TUTOR_MANAGMENT: {
    TITLE: 'Popis instruktora',
    UNPROCESSED: 'NEOBRAĐENO',
    APPROVED: 'ODOBRENO',
    DENIED: 'ODBIJENO',
    SUBJECT_LIST: {
      MORE: 'više',
    },
    NO_RESULT: {
      TITLE: 'Nema rezultata',
      DESC: ' Hmm, izgleda da nema instruktora za ovu određenu pretragu.',
    },
    TABLE: {
      FIRST_NAME: 'IME',
      LAST_NAME: 'PREZIME',
      EMAIL: 'EMAIL',
      COUNTRY: 'DRŽAVA',
      DATE_OF_BIRTH: 'DATUM ROĐENJA',
      PREVIEW_PROFILE: 'Pregled profila',
      PHONE_NUMBER: 'BROJ TELEFONA',
      CREATED_AT: 'DATUM KREIRANJA',
      ROLE: 'ULOGA',
      CREDITS: 'KREDITI',
      NUMBER_OF_LESSONS: 'PREDAVANJA',
      ID_VERIFIED: 'POTVRÐEN IDENTITET',
      DOCUMENTS_UPLOADED: 'DOKUMENTI UČITANI',
    },
    STUDENT_MANAGEMENT: {
      TITLE: 'Popis studenata/roditelja',
      NO_RESULT: {
        TITLE: 'Nema rezultata',
        DESC: ' Hmm, izgleda da nema studenata/roditelja za ovu određenu pretragu.',
      },
      SEARCH_PLACEHOLDER: 'Pronađi studenta/roditelja',
      TABLE: {
        FIRST_NAME: 'IME',
        LAST_NAME: 'PREZIME',
        EMAIL: 'EMAIL',
        PHONE_NUMBER: 'BROJ TELEFONA',
        ROLE: 'ULOGA',
        NUMBER_OF_LESSONS: 'LESSONS',
        CREDITS: 'CREDITS',
        STUDENT: 'STUDENT',
        LEVEL: 'RAZINA',
        SUBJECT: 'PREDMET',
        TUTOR: 'INSTRUKTOR',
        TUTOR_EMAIL: 'EMAIL INSTRUKTORA',
        TUTOR_PHONE: 'BROJ TELEFONA INSTRUKTORA',
        START_TIME: 'VRIJEME POČETKA',
        CREATED_AT: 'KREIRANO',
        PRICE: 'CIJENA',
        STATE: 'STANJE',
        ACCEPTED: 'PRIHVAĆENO',
        DELETED: 'OBRISANO',
        RESCHEDULE: 'RESCHEDULE',

      },
    },
    ACTIONS: {
      APPROVE: 'Odobri',
      DECLINE: 'Odbij',
      DELETE: 'Izbriši',
      APPROVE_TUTOR: 'Odobri instruktora',
      DECLINE_TUTOR: 'Odbij instruktora',
      DELETE_TUTOR: 'Izbriši instruktora',
      EDIT_NOTE: 'Uredi bilješku',
      CANCEL: 'Odustani',
      PLAY_VIDEO: 'Pogledaj video',
    },
    NOTE: 'Bilješka',
    NOTE_PLACEHOLDER: 'Unesi bilješku...',
    DETAILS: ' DETALJI',
    SEARCH_PLACEHOLDER: 'Pronađi instruktora',
    VIDEO_PREVIEW: {
      DECLINE_AND_SEND_BUTTON: 'Odbij i pošalji',
      MESSAGE_TITLE: 'Razlog odbijanja videa',
    },
  },
  CHAT: {
    PLACEHOLDER: 'Pošaljite poruku kako biste započeli razgovor',
    SEARCH_PLACEHOLDER: 'Pretražite razgovor',
    BOOK_SESSION: 'Rezervirajte lekciju',
    FREE_CONSULTATION: 'Započnite besplatan poziv',
    ACCEPT_CONSULTATION: 'Prihvati',
    DENY_CONSULTATION: 'Odbij',
    DENY_FREE_CONSULTATION: 'Odustanite od poziva',
    CHAT_REQUEST_TITLE: 'Dolazni videopoziv',
    CHAT_MISSED_CALL: '<span class="chat-missed-incoming-message">Propušten poziv od korisnika userInsert={user}</span>',
  },
  ROLE_SELECTION: {
    TITLE: 'Registracija',
    ACTION: 'Želim se registrirati kao',
    ACCOUNT: 'Već imate korisnički račun?',
    LOG_IN: 'Prijavite se',
    STUDENT_TITLE: 'Učenik',
    STUDENT_DESCRIPTION: 'Ovdje sam da proširim svoje znanje.',
    PARENT_TITLE: 'Roditelj / Skrbnik',
    PARENT_DESCRIPTION: 'Ovdje sam za svoje dijete.',
    TUTOR_TITLE: 'Instruktor',
    TUTOR_DESCRIPTION: 'Ovdje sam da podučavam učenike.',
  },
  RESET_PASSWORD: {
    TITLE: 'Zaboravili ste lozinku?',
    FORM: {
        EMAIL: 'Email',
        SUBMIT_BTN: 'Resetiraj lozinku',
    },
    SUCCESS: 'Uspješno ste resetirali lozinku. Molimo provjerite svoj email.',
    BACK_BTN: 'Povratak na prijavu',
    PASSWORD_CHANGED_SUCCESS: 'Lozinka uspjesno promijenjena, biti ćete prebačeni na stranicu za prijavu',
    EMAIL_SENT: 'E-mail poslan, biti ćete prebačeni na stranicu za prijavu',
  },
  LOGIN: {
    TITLE: 'Prijava',
    FORM: {
        EMAIL: 'Email',
        PASSWORD: 'Lozinka',
        SUBMIT_BTN: 'Prijava',
    },
    FORGOT_PASSWORD: 'Zaboravili ste lozinku?',
    ACCOUNT: 'Nemate korisnički račun?',
    REGISTER: 'Registrirajte se',
  },
  BACKEND_ERRORS: {
    BASE: {
      UNAUTHORIZED: 'Niste ovlašteni.',
      FORBIDDEN: 'Nemate dopuštenje.',
      NOT_FOUND: 'Resurs nije pronađen',
      CONFLICT: 'Resurs već postoji',
      INTERNAL_ERROR: 'Pogreška servera',
      BAD_REQUEST: 'Loš zahtjev',
    },
    USER: {
      EMAIL_CONFLICT: 'Korisnik s ovom email adresom već postoji',
      LOGIN_WRONG_FORM_DATA: {
        BODY: 'Pogrešni podaci. Molimo vas pokušajte ponovo.',
        TITLE: "Probajmo još jednom",
      },
      NOT_FOUND: 'Korisnik nije pronađen',
      USERNAME_CONFLICT: 'Korisničko ime već postoji',
      PROFILE_NOT_ACTIVE: {
        SENT_AGAIN: 'Molimo potvrdite vašu e-mail adresu.',
      },
      PASSWORD_RESET: {
        TOKEN_EXPIRED: 'Vaš token je istekao, molimo zatražite novi e-mail',
        TOKEN_INVALID: 'Vaš token je neispravan, molimo zatražite novi e-mail',
        MISMATCH: 'Lozinke se ne podudaraju',
      },
    },
    LEVEL: {
      NOT_FOUND: 'Razina nije pronađena',
    },
    FILE: {
      NOT_FOUND: 'Dokument nije pronađen',
    },
    SUBJECT: {
      NOT_FOUND: 'Predmet nije pronađen',
    },
    TUTOR: {
      NOT_FOUND: 'Instruktor nije pronađen',
    },
    TUTOR_AVAILABILITY: {
      CONFLICT: 'Dostupnost instruktora već je određena',
      NOT_FOUND: 'Dostupnost instruktora nije pronađena',
    },
    BOOKING: {
      NOT_FOUND: 'Rezervacija nije pronađena',
    },
    ROLE: {
      NOT_FOUND: 'Uloga nije pronađena',
    },
    REVIEW: {
      CONFLICT: 'Već ste napisali recenziju za ovog instruktora',
    },
    INPUT_FAILED: 'Pogrešni ulazni podatci',
    INVALID_IMAGE: 'Nevažeća slika',
  },
  NOT_FOUND: {
    TITLE: 'Stranica nije pronađena',
    SUBTITLE: ' Oprostite, izgleda da smo Vas poslali pogrešnim putem. Dopustite nam da Vas vratimo natrag!',
    BACK_BUTTON: 'Povratak na početak',
  },
  UPCOMING_LESSONS: {
    TITLE: 'NADOLAZEĆI DOGAĐAJI',
    EMPTY: 'Nema nadolazećih lekcija',
  },
  SKIP_FOR_NOW: 'Zanemari za sada',
  REGISTER: {
    TITLE: 'Registracija',
    FORM: {
        BUTTONS_SUBTITLE: 'Tip računa',
        BUTTON_PARENT: 'Roditelj',
        BUTTON_STUDENT: 'Učenik',
        BUTTON_TUTOR: 'Instruktor',
        NAME_SUBTITLE: 'Ime',
        FIRST_NAME: 'Ime kao na osobnoj iskaznici',
        FIRST_NAME_PLACEHOLDER: 'Enter First Name',
        LAST_NAME: 'Prezime kao na osobnoj iskaznici',
        LAST_NAME_PLACEHOLDER: 'Enter Last Name',
        LAST_NAME_INFO: 'Provjerite da ovo odgovara imenu na Vašoj službenoj osobnoj iskaznici.',
        DATE_OF_BIRTH_SUBTITLE: 'Datum rođenja',
        DATE_OF_BIRTH: 'Datum rođenja',
        DATE_OF_BIRTH_PLACEHOLDER: 'Enter your birthdate',
        DATE_OF_BIRTH_INFO: 'Kako biste se registrirali na Teorem, morate imati najmanje 18 godina.',
        CONTACT_INFO_SUBTITLE: 'Kontaktni podaci',
        EMAIL: 'Email',
        EMAIL_PLACEHOLDER: 'Enter Email',
        PHONE_NUMBER: 'Broj telefona',
        PHONE_INFO: 'Vaši kontakt podaci neće biti vidljivi javnosti. E-poštom ćemo Vam slati potvrde predavanja i račune.',
        PASSWORD_SUBTITLE: 'Lozinka',
        PASSWORD: 'Lozinka',
        PASSWORD_PLACEHOLDER: 'Type your password',
        CONFIRM_PASSWORD: 'Repeat Password',
        CONFIRM_PASSWORD_PLACEHOLDER: 'Type your password',
        AGREE: 'Klikom na Slažem se i nastavi, slažem se s Uvjetima pružanja usluge i Politikom privatnosti Teorema.',
        SUBMIT_BUTTON: 'Slažem se i nastavi',
        MARKETING_DISCLAIMER:
            'Teorem će vam slati posebne ponude, marketinše e-poruke i obavijesti. Možete isključiti njihovo primanje u bilo kojem trenutku u postavkama svog računa ili izravno iz marketinške obavijesti.',
        MARKETING_DISCLAIMER_CHECKBOX: 'Ne želim primati marketinške poruke od Teorema.',
        CHILD_NAME: 'Ime djeteta',
        CHILD_NAME_PLACEHOLDER: 'Unesite ime djeteta',
        CHILD_DATE_OF_BIRTH: 'Datum rođenja djeteta*',
        USERNAME: 'Korisničko ime*',
        EMAIL_EXISTS: 'Ovaj email se već koristi. Molimo ulogirajte se.',
        TERMS_AND_CONDITIONS:
            '<div>Klikom na <strong>Slažem se i nastavi</strong>, slažem se s <a href="https://teorem.hr/hr/uvjeti-koristenja" target="_blank">Uvjetima pružanja usluge</a> i <a href="https://teorem.hr/hr/politika-privatnosti" target="_blank">Politikom privatnosti</a> Teorema.</div>',
    },
    CARD_DETAILS: {
      FIRST_NAME: 'Ime*',
      LAST_NAME: 'Prezime*',
      CARD_NUMBER: 'Broj kartice*',
      EXPIRY_DATE: 'Datum isteka*',
      EXPIRY_PLACEHOLDER: 'MM / GG',
      CVV: 'CVV*',
      ZIP_CODE: 'Poštanski broj*',
      ZIP_CODE_PLACEHOLDER: 'Unesite poštanski broj',
    },
    BACK_BUTTON: 'Natrag na odabir',
    NEXT_BUTTON: 'Dalje',
    BACK_TO_REGISTER: 'Natrag na registraciju',
    FINISH: 'Završi',
    BACK_TO_STEP_ONE: 'Natrag na korak 1',
    BACK_TO_STEP_TWO: 'Natrag na korak ',
    SAVE_BUTTON: 'Spremi',
    DELETE_BUTTON: 'Obriši',
    BACK_TO_LIST: 'Natrag na popis',
    NAVIGATION: {
      STEP1: {
        TITLE: 'Osobni podatci',
        DESC: 'Dopustite nam da Vas bolje upoznamo',
      },
      STEP2: {
        TITLE: 'Popis djece',
        DESC: 'Ispunite podatke o Vašem djetetu i postavite njegove/njezine podatke za prijavu.',
      },
    },
  },
  ADD_CHILD: {
    TITLE: 'Dodajte novo dijete',
    DESCRIPTION: 'Izaberite za dodavanje novog djeteta',
    SAVE: 'Spremi dijete',
    PART_1: 'Dodajte ',
    PART_2: 'dijete',
  },
  MY_BOOKINGS: {
    TITLE: 'Kalendar',
    NOTIFICATION_PART_1: 'Imate ',
    NOTIFICATION_PART_2: ' lekcija danas!',
    UNAVAILABLE: 'Nedostupno',
    UNAVAILABILITY: 'Nedostupnost',
    UNABLE_MESSAGE: 'Ne možete dodati događaj u prošlosti ili u iduća 3 sata',
    TIME_AFTER: 'Vrijeme početka ne može biti nakon vremena završetka',
    TIME_SAME: 'Vrijeme početka i vrijeme završetka ne mogu biti isti',
    CALENDAR: {
      TITLE: 'KALENDAR',
      TODAY: 'Danas',
    },
    ADDITIONAL_INFORMATION: {
      TITLE: 'Informacije o pridruživanju na sastanak',
    },
    MODAL: {
      DELETED_USER: 'Izbrisani korisnik',
      ACCEPT: 'Prihvati',
      PROPOSE: 'Predloži novo vrijeme',
      DELETE: 'Izbriši',
      DENY: 'Odbij',
      TUTOR_CALENDAR: 'Raspored instruktora',
      TIME: 'Vrijeme',
      DATE: 'Datum',
      WHOLE_DAY: 'Čitav dan',
      SET_UNAVAILABILITY: 'Postavi nedostupnost',
      CANCEL: 'Otkaži',
      CONFIRM_CANCEL_TITLE: 'Jeste li sigurni da želite otkazati rezervaciju?',
    },
    INFORMATION: {
      TITLE: 'INFORMACIJE O REZERVACIJAMA',
      CARD1: {
        TITLE: 'Kako se rade rezervacije?',
        DESC: 'Nakon odabira vremena koje vam odgovara i rezervacije, instruktor ima 24 sata da prihvati vaš zahtjev za rezervaciju. Obavijestit ćemo vas e-poštom kada vaša lekcija bude prihvaćena.',
      },
      CARD2: {
        TITLE: 'Što ako mi moj termin predavanja vise ne odgovara?',
        DESC: 'Možete otkazati bilo koju rezervaciju i dobiti puni povrat novca 24 sata od trenutka kada ste izvršili uplatu. Za više informacija posjetite naš <a href="https://intercom.help/teorem/hr/" target="_blank">centar za podršku</a>.',
      },
      CARD3: {
        TITLE: 'Kako rezervirati termin?',
        DESC: 'Možete rezervirati termin s instruktorom odlaskom na njegov/njezin profil i pritiskom na "Rezervirajte lekciju". Moći ćete vidjeti instruktorovu dostupnost i izabrati vrijeme koje odgovara za oboje.',
      },
      CARD4: {
        TITLE: 'Kako otkazati rezervaciju?',
        DESC: 'Možete otkazati rezervaciju u Vašem kalendaru klikom na rezervaciju i zatim na "Otkaži". Ako otkažete rezervaciju više od 24 sata unaprijed, dobit ćete puni povrat novca.',
      },
    },
    RESCHEDULE: {
      BUTTON: 'Novi termin',
      MODAL: {
        TITLE: 'Promjena termina',
        DESCRIPTION:
          'Ako već niste, predlažemo da se dogovorite s drugom stranom o novom terminu i da budete sigurni da će prihvatiti novi termin.',
      },
    },
    CANCEL: {
      BUTTON: 'Otkaži',
      MODAL: {
        TITLE: 'Jeste li sigurni da želite otkazati termin?',
        STUDENT_DESCRIPTION: 'Povrat novca možete dobiti u obliku creditsa na Teorem platformi.',
        TUTOR_DESCRIPTION:
          'U slučaju da otkazujete rezervaciju manje od 24h prije početka predavanja, biti ćete penalizirani tako što će se vaš profil manje prikazivati u pretrazi.',
        CONFIRM: 'Otkaži predavanje',
        DISMISS: 'Odustani',
      },
    },
  },
  BOOK: {
    TITLE: 'Rezervirajte termin',
    RESCHEDULE_TITLE: 'Ažuriraj rezervaciju',
    FORM: {
      LEVEL: 'Razina',
      LEVEL_PLACEHOLDER: 'Odaberite razinu',
      SUBJECT: 'Predmet',
      CHILD: 'Dijete',
      CHILD_PLACEHOLDER: 'Odabir djeteta',
      TIME: 'Vrijeme* (Lekcije traju 50min)',
      TIME_PLACEHOLDER: 'Vrijeme',
      SUBMIT: 'Rezerviraj i Plati',
      ADD_CARD: 'Dodaj karticu',
      UPDATE: 'Ažuriraj rezervaciju',
      CANCEL: 'Otkaži',
      TUTOR_DISABLED: 'Privremeno nedostupno',
      CANCEL_BOOKING: 'Otkaži rezervaciju',
      EDIT_OR_CANCEL_BOOKING: 'Izmijeni ili otkaži',
      EDIT_OR_DENY_BOOKING: 'Izmijeni ili odbij',
      DISMISS: 'Odustani',
      DATE_OF_BOOKING: 'Datum',
      TIME_OF_BOOKING: 'Vrijeme',
      ALREADY_IN_RESCHEDULE: 'U procesu promjene termina',
      USE_CREDITS: 'Iskoristi kredite',
    },
    JOIN: 'Pridruži se',
    JOIN_TOOLTIP: 'Sastanku se možete pridružiti najranije 5 minuta prije početka',
  },
  MY_REVIEWS: {
    TITLE: 'Moje recenzije',
    COUNT_TITLE: 'RECENZIJE',
    AVG_SCORE: 'Prosječna ocjena',
    PUBLISHED: 'Objavljeno',
    STAR: {
      1: '1 zvjezdica',
      2: '2 zvjezdice',
      3: '3 zvjezdice',
      4: '4 zvjezdice',
      5: '5 zvjezdica',
    },
    NO_RESULT: {
      TITLE: 'Bez rezultata',
      DESC: 'Još nema recenzija.',
      DESC_ADMIN: 'Još uvijek nema niti jedne recenzije.',
    },
    DATE_AGO: {
      BEFORE: ' prije ',
      YEAR: ' godina',
      YEARS: ' godina',
      MONTH: ' mjesec',
      MONTHS: ' mjeseci',
      WEEK: ' tjedan',
      WEEKS: ' tjedna',
      DAY: ' dan',
      DAYS: ' dana',
      TODAY: ' danas',
    },
  },
  COMPLETED_LESSONS: {
    TITLE: 'Završene lekcije',
    TUTORS_AVAILABLE: 'Dostupni instruktori',
    LESSONS_AVAILABLE: 'Dostupne lekcije',
    EMPTY_LESSONS_LIST: 'Nema dostupnih završenih lekcija',
    EMPTY_LESSONS_TITLE: 'Nema lekcija',
    LEAVE_REVIEW: 'Ostavite recenziju',
    VIEW_CALENDAR: 'Pregledajte kalendar',
    BOOK_LESSON: 'Rezerviraj predavanje',
    VIDEOS_TITLE: 'Preuzmite račune i video lekcije',
    EMPTY_VIDEOS: 'Nema dostupnih završenih lekcija za odabir',
    LINK: 'Idi na kalendar',
    COUNT_EXTENSION: 'Lekcija',
    TOOLTIP_DOWNLOAD_INVOICE: 'Preuzmi račun',
    TOOLTIP_DROPDOWN_LABEL: 'Prikaži video lekcije',
    TOOLTIP_PLAY_LESSON: 'Pokreni/preuzmi video lekciju',
    DATE_TITLE: 'Datum',
    DATE_AT_WORD: 'u',
    DOWNLOAD_INVOICE_SUCCESS: 'Preuzimanje računa uspješno!',
    DOWNLOAD_INVOICE_FAIL: 'Preuzimanje računa neuspješno!',
    NUMBER_OF_COMPLETED_LESSONS: ' završenih predavanja s ovim instruktorom',
  },
  WRITE_REVIEW: {
    SECTION_TITLE: 'Napišite recenziju',
    SUBTITLE:
      'Vaše mišljenje važno je za poboljšanje naše usluge instrukcija. Odvojite trenutak da podijelite svoje iskustvo i pomognete drugima poput Vas da pronađu savršenog instruktora!',
    OVERALL: 'Sveukupno',
    PUNCTUALITY: 'Točnost',
    COMMUNICATION: 'Komunikacija',
    KNOWLEDGE: 'Znanje',
    REVIEW: 'Recenzija',
    SUBMIT: 'Objavi',
    CANCEL: 'Odustani',
    CLOSE: 'Zatvori',
    FINISH: 'Završi',
    HEADING_PLACEHOLDER: 'Napiši naslov svoje recenzije',
    TEXT_PLACEHOLDER: 'Opišite Vaše iskustvo s ovim instruktorom',
    REVIEWS: 'recenzije',
    THANKS_MESSAGE: 'Hvala na recenziji!',
    NEW_TUTOR: 'Novi instruktor',
    COMPLETED_LESSONS: {
      PART_1: 'Završili ste ',
      PART_2: ' predavanja s ovim instruktorom.',
    },
    COMMENT_LABEL: 'Opišite Vaše iskustvo s ovim instruktorom.',
  },

  COMPLETE_PROFILE: {
    TITLE: 'Završi svoj profil',
    DESCRIPTION: 'Ispuni nedostajeće podatke kako bi završio svoj profil',
    PROFILE_SETTINGS: 'Postavke profila',
    GENERAL_AVAILABILITY: 'Dostupnost',
    MY_TEACHINGS: 'Predmeti',
    ABOUT_ME: 'O meni',
    ACCOUNT: 'Korisnički račun',
    CHILD_INFO: 'Informacije o djetetu',
    EARNINGS: 'Zarada',
  },
  COMPLETE_TUTOR_PROFILE_CARD: {
    TITLE: 'Završi svoj profil!',
    DESCRIPTION: 'Molimo ispunite ostatak podataka o svojem profilu kako bi počeli predavati.',
  },
  TUTOR_VERIFIED_NOTE: {
    TITLE: 'Vaš račun mora potvrditi administrator, prije nego što se može prikazivati u rezultatima pretraživanja!',
    DESCRIPTION: 'Primit ćete e-mail o statusu vašeg računa.',
  },
  CHILDLESS_PARENT_NOTE: {
    TITLE: 'Morate imati dodano dijete kako biste mogli zatražiti instrukcije!',
    DESCRIPTION: 'Dijete možete dodati na postavkama profila.',
  },
  ACCOUNT: {
    CHANGE_PASSWORD: {
      TITLE: 'Promijenite lozinku',
      DESCRIPTION: 'Potvrdite svoju trenutnu lozinku te nakon toga unesite novu.',
      CURRENT_PASSWORD: 'Trenutna lozinka',
      CURRENT_PASSWORD_PLACEHOLDER: 'Unesite trenutnu lozinku',
      NEW_PASSWORD: 'Nova lozinka',
      NEW_PASSWORD_PLACEHOLDER: 'Unesite novu lozinku',
      CONFIRM_PASSWORD: 'Potvrdi lozinku',
      CONFIRM_PASSWORD_PLACEHOLDER: 'Potvrdi lozinku',
    },
    CARD_DETAILS: {
      TITLE: 'Plaćanje',
      DESCRIPTION: 'Odaberi osnovnu metodu plaćanja ili dodaj novu.',
      TITLE_TUTOR: 'Detalji plaćanja',
      DESCRIPTION_TUTOR: 'Odaberi kako zeliš biti plaćen za instrukcije.',
      ADD_NEW: 'Dodaj novu karticu',
      ADD_NEW_DESC: 'Izaberi za dodavanje nove kartice',
      NOTE: 'Nakon dodavanja kartice, slijedi proces verifikacije koji uključuje privremenu naplatu od €0.00.',
    },
    NEW_CARD: {
      ADD: 'DODAJTE NOVU KARTICU',
      NAME: 'Ime',
      NAME_PLACEHOLDER: 'Unesi ime',
      SURNAME: 'Prezime',
      SURNAME_PLACEHOLDER: 'Unesi prezime',
      CITY: 'Grad',
      CITY_PLACEHOLDER: 'Unesi ime grada',
      ADDRESS1: 'Adresa 1',
      ADDRESS1_PLACEHOLDER: 'Unesi prvu adresu',
      ADDRESS2: 'Adresa 2',
      ADDRESS2_PLACEHOLDER: 'Unesi drugu adresu',
      CARD_NUMBER: 'Broj kartice',
      CARD_NUMBER_PLACEHOLDER: '**** **** **** ****',
      EXPIRY: 'Datum isteka',
      EXPIRY_PLACEHOLDER: 'MM / GG',
      CVV: 'CVV*',
      CVV_PLACEHOLDER: '***',
      ADD_BUTTON: 'Dodaj novu karticu',
      CANCEL_BUTTON: 'Odustani',
      ZIP: 'Zip',
      ZIP_PLACEHOLDER: '12345',
      COUNTRY: 'Država',
      COUNTRY_PLACEHOLDER: 'Izaberite državu',
    },
    SUBMIT: 'Spremi',
  },
  MY_PROFILE: {
    TITLE: 'Moj profil',
    PREVIEW: 'Pregled profila',
    PAYOUTS: 'Gdje ti isplaćujemo zaradu?',
    IMAGE: 'Dodaj svoju sliku profila',
    ABOUT_ME: {
      OCCUPATION: 'Vaše trenutno zanimanje*',
      OCCUPATION_PLACEHOLDER: 'Unesite Vaše trenutno zanimanje',
      YEARS: 'Broj godina iskustva u podučavanju',
      YEARS_PLACEHOLDER: 'Koliko godina iskustva u podučavanju imate',
      ABOUT_YOURSELF: 'Recite nam više o sebi*',
      ABOUT_LESSON: 'Recite nam više o Vašem načinu predavanja*',
    },
    MY_TEACHINGS: {
      TITLE: 'Moji predmeti',
      DESCRIPTION: 'Izmijenite i ažurirajte svoje predmete',
      ADD_NEW: 'Dodajte novi predmet',
      ADD_DESC: 'Izaberite za dodavanje novog predmeta',
      DELETED: 'Predmet je obrisan',
      CREATED: 'Predmet je dodan',
      UPDATED: 'Predmeti ažurirani',
      EDIT_TITLE: 'IZMIJENITE PREDMET',
      ADD_TITLE: 'DODAJTE NOVI PREDMET',
      SUBJECT: 'Odaberite predmet koji predajete',
      LEVEL: 'Odaberite razine koje predajete',
      PRICING: 'Cijena ',
      PRICING_PLACEHOLDER: '10',
      HOUR_ABRV: 'h',
      SAVE: 'Spremi podatke',
      DELETE: 'Izbriši',
      CANCEL: 'Odustani',
      SUBJECT_ERROR: 'Predmet za tu razinu već postoji',
    },
    GENERAL_AVAILABILITY: {
      TITLE: 'Dostupnost',
      DESCRIPTION: 'Izmijenite i ažurirajte svoju dostupnost',
      UPDATED: 'Dostupnost ažurirana',
      CREATED: 'Dostupnost dodana',
      SELECT_ZONE: 'Odaberite svoju vremensku zonu: ',
      TIME_ZONE: 'Vremenska zona',
    },
    PROFILE_SETTINGS: {
      TITLE: 'Osobni podatci',
      CREDITS: 'Krediti',
      DESCRIPTION: 'Izmijenite i ažurirajte svoje osobne podatke',
      FIRST_NAME: 'Ime*',
      FIRST_NAME_PLACEHOLDER: 'Unesite svoje ime',
      LAST_NAME: 'Prezime*',
      LAST_NAME_PLACEHOLDER: 'Unesite svoje prezime',
      PHONE: 'Broj telefona*',
      COUNTRY: 'Država*',
      BIRTHDAY: 'Datum rođenja*',
      IBAN: 'IBAN',
      IBAN_PLACEHOLDER: 'Unesite svoj IBAN',
      COMPANY_NAME: 'Ime tvrtke',
      COMPANY_NAME_PLACEHOLDER: 'Unesite ime tvrtke',
      COMPANY_OIB: 'OIB tvrtke',
      COMPANY_OIB_PLACEHOLDER: 'Unesite OIB tvrtke',
      IMAGE: 'Profilna slika*',
      UPLOAD_IMAGE: 'Kliknite ili povucite i ispustite za učitavanje',
      CHOOSE_FILE: 'Odaberite datoteku',
      ADDRESS_PERSONAL_PLACEHOLDER: 'Unesite Vašu adresu',
      ADDRESS_BUSINESS_PLACEHOLDER: 'Unesite adresu Vaše tvrtke',
    },
    PROFILE_ACCOUNT: {
      STRIPE: 'Idi na Stripe',
      STRIPE_ALREADY_CONNECTED: 'Stripe račun je već povezan,ukoliko želite povezati drugi račun, ponovo pritisnite tipku ispod',
      STRIPE_CONNECTED: 'Povezano',
      STRIPE_DISCONNECTED: 'Nije Povezano',
      STRIPE_CARD_DECLINED: 'Greška prilikom izrade Stripe računa',
      SUCCESS_PASSWORD: 'Lozinka je uspješno izmijenjena',
      STRIPE_DEFAULT_PAYMENT_METHOD_UPDATED: 'Primarna metoda plaćanja je izmijenjena',
    },
    CHILD: {
      ADD_TITLE: 'DODAJTE DIJETE',
      EDIT_TITLE: 'AŽURIRAJTE DIJETE',
      FIRST_NAME: 'Ime',
      FIRST_NAME_PLACEHOLDER: 'Unesite ime djeteta',
      LAST_NAME: 'Prezime',
      LAST_NAME_PLACEHOLDER: 'Unesite prezime djeteta',
      USERNAME: 'Korisničko ime',
      USERNAME_PLACEHOLDER: 'Unesite korisničko ime',
      PASSWORD: 'Unesite novu lozinku',
      PASSWORD_PLACEHOLDER: 'Upišite novu lozinku',
      PASSWORD_OPTIONAL: 'Ovo je polje izborno, ako želite promijeniti djetetovu trenutnu lozinku možete to učiniti u polju ispod.',
      DELETE: 'Izbriši',
      CANCEL: 'Odustani',
    },
    TRANSLATION: {
      TITLE: 'Prijevod',
      SUBTITLE: 'Izaberite jezik aplikacije.',
    },
    SUBMIT: 'Spremi',
    TUTOR_DISABLE: {
      TITLE: 'Vidljivost profila',
      SUBTITLE: 'Profil se prikazuje',
      YES: 'Da',
      NO: 'Ne',
    },
  },
  DASHBOARD: {
    TITLE: 'Upravljačka ploča',
    REQUESTS: {
      TITLE: 'ZAHTJEVI ZA PREDAVANJIMA',
      EMPTY: 'Nema novih zahtjeva',
      RESCHEDULE: 'ZAHTJEVI ZA PROMJENU TERMINA',
      STATUS: {
        RESCHEDULE_REQUESTED: 'Izmjena',
        RESCHEDULE_DO_ACTION: 'Izmjena',
        NEW_BOOKING_REQUESTED: 'Čekanje potvrde',
        NEW_BOOKING_DO_ACTION: 'Nova rezervacija',
      },
      TOOLTIP: {
        PART_1: 'Imate još ',
        PART_2: ' h da prihvatite rezervaciju',
      },
    },
    SCHEDULE: {
      TITLE: 'DANAŠNJI RASPORED',
      BUTTON: 'Spajanje',
      EMPTY: 'Danas nema zakazanih predavanja',
    },
    MESSAGES: {
      TITLE: 'NEPROČITANE PORUKE',
      BUTTON: 'Razgovori',
      EMPTY: 'Nema nepročitanih poruka',
    },
    BOOKINGS: {
      TITLE: 'NADOLAZEĆI TERMINI',
      TOTAL: 'Ukupno',
      EMPTY: 'Nema zakazanih termina',
      EMPTY_SUBTITLE: 'Hmm, izgleda da za sada nemate zakazanih termina.',
      RECOMMENDED: 'PREPORUČENI INSTRUKTORI',
      SHOW_MORE: 'Prikaži više',
    },
    NOTIFICATIONS: {
      TITLE: 'OBAVIJESTI',
      CLEAR: 'Označi kao pročitano',
      ALL: 'Vidi sve',
      EMPTY: 'Nema nepročitanih obavijesti',
    },
    COMING_SOON: {
      TITLE: 'Stiže uskoro!',
      SUBTITLE: 'Naš tim marljivo radi kako bismo dodali ovu funkcionalnost',
    },
  },
  NOTIFICATIONS: {
    TITLE: 'Sve obavijesti',
    EMPTY: 'Nema obavijesti',
    BOOKING_CLASS_STARTING: {
      DESCRIPTION: 'Predavanje započinje uskoro',
      TITLE: 'Predavanje započinje uskoro',
    },
    CHAT_MISSED_CALL: {
      DESCRIPTION: 'Propušten videopoziv od',
      TITLE: 'Propušten videopoziv',
    },
    BOOKING: {
      STUDENT: 'Učenik',
      PARENT: 'Roditelj',
      TUTOR: 'Instruktor',
    },
    BOOKING_NEW_BOOKING: {
      TITLE: 'Nova rezervacija',
      DESCRIPTION: '',
      MADE_BOOKING_TEXT: 'je napravio novu rezervaciju za',
    },
    BOOKING_RESCHEDULE_PROPOSITION: {
      TITLE: 'Predloženo ponovno zakazivanje',
      DESCRIPTION: '',
      PROPOSING_NEW_TIME_TEXT: 'želi predložiti novo vrijeme za rezervaciju',
    },
    BOOKING_ACCEPTED: {
      TITLE: 'Rezervacija prihvaćena',
      DESCRIPTION: 'je prihvatio/la rezervaciju za',
    },
    BOOKING_CANCELED: {
      TITLE: 'Rezervacija otkazana',
      DESCRIPTION: '',
      CANCEL_TEXT: 'je otkazao/la',
    },
    BOOKING_DELETED: {
      TITLE: 'Neprihvaćena rezervacija uklonjena',
      DESCRIPTION: {
        PART1: 'Rezervacija je uklonjena jer ju',
        PART2: 'nije prihvatio/la.',
      },
    },
    CHAT_HAS_MISSED_CALL: ' je propustio Vaš poziv',
    HELP_CENTER: {
      TITLE: 'Centar za podršku',
      LINK: 'https://intercom.help/teorem/hr/',
    },
  },
  EARNINGS: {
    TITLE: 'Prihod',
    GENERAL: {
      TITLE: 'STATISTIKA',
      BOOKINGS: 'Ukupno rezervacija',
      STUDENTS: 'Ukupno učenika',
      REVIEWS: 'Ukupno recenzija',
      REVENUE: 'Ukupan prihod',
      CURRENCY: '€',
    },
    PAYOUTS: 'Isplata',
    WEEK_TITLE: 'Tjedan',
    WEEK: 'Zadnjih 7 dana',
    MONTH: 'Zadnjih 30 dana',
    YEAR: 'Zadnjih 12 mjeseci',
    ALLTIME: 'Oduvijek',
    REVENUE: {
      TITLE: 'PRIHOD',
      GRAPH_LEGEND: 'Prihod',
    },
    STUDENTS: {
      TITLE: 'UČENICI',
      GRAPH_LEGEND: 'Učenici',
    },
    BOOKINGS: {
      TITLE: 'REZERVACIJE',
      GRAPH_LEGEND: 'Rezervacije',
    },
    DETAILS: {
      TITLE: 'DETALJI',
      TABLE: {
        MONTH: 'Mjesec',
        BOOKINGS: 'Rezervacije',
        STUDENTS: 'Učenici',
        REVIEWS: 'Recenzije',
        REVENUE: 'Prihod',
        PROVISION: 'Provizija Teorema',
        PAYOUT: 'Isplata',
        EMPTY: 'Nema detalja',
      },
    },
  },
  ROUTER_MODAL: {
    TITLE: 'Spremiti promjene?',
    DESC: 'Želite li spremiti svoje promjene prije odlaska s ove stranice?',
    SAVE: 'Spremi',
    NO: 'Nemoj spremiti',
  },
  TUTOR_PROFILE: {
    AVAILABILITY_EMPTY: 'Instruktor nije ispunio tablicu dostupnosti',
    PRICING: 'Cijena',
    RATING_TITLE: 'Ocjena',
    COMPLETED_LESSONS: 'Odrađenih lekcija',
    BOOK: 'Rezerviraj lekciju',
    TUTOR_DISABLED: 'Privremeno nedostupno',
    SEND: 'Pošalji poruku',
    EMPTY: 'Korisnik nije pronađen',
    ID_VERIFIED: 'Potvrđen identitet',
    DOCUMENTS_UPLOADED: 'Dokumenti učitani',
    SUBJECTS: {
      TITLE: 'Predmeti',
      SUBJECT: 'Predmet',
      QUALIFICATION: 'Kvalifikacija',
      PRICE: 'Cijena',
      HOUR_ABRV: 'h',
      EMPTY: 'Nema ponuđenih predmeta',
    },
    RATING: {
      TITLE: 'Ocjene i recenzije',
      TOTAL: 'ukupno recenzija',
    },
    PRE12: '08-12h',
    ON12: '12-17h',
    AFTER5: '17-20h',
    REVIEWS: 'recenzija',
    TOOLTIP: {
      ID_VERIFIED: 'Instruktor ima potvrđen identitet',
    },
  },
  LANDING: {
    HEADER: {
      NAV_1: 'Kako radimo',
      NAV_2: 'Cijene',
      NAV_3: 'Postanite instruktor',
      SIGN_IN: 'Prijavite se',
      GET_STARTED: 'Započnite danas',
    },
    FOOTER: {
      TITLE_TEXT: 'Ako imate dodatnih pitanja, slobodno nas kontaktirajte putem emaila ',
      APP_NAME: 'Teorem Ltd',
      ADDRESS: '',
      MOBILE: '',
      EMAIL: 'info@teorem.co',
      LEGAL: {
        TITLE: 'O Teoremu',
        LEGAL: 'Uvjeti korištenja',
        PRIVACY: 'Politika privatnosti',
        TUTOR: 'Postanite instruktor',
      },
      PAYMENT: {
        TITLE: 'Metode plaćanja',
      },
    },
  },
  HOW_IT_WORKS: {
    HERO: {
      TITLE: 'Online instrukcije koje oslobađaju potencijal',
      SUBTITLE:
        'Privatna 1-na-1 online predavanja od 50 minuta jedinstveno skrojena za svakog učenika. Svi školski predmeti i dobne skupine pokrivene.',
    },
    SUBTITLE_1: 'Predstavljeno diljem svijeta',
    SUBTITLE_2: 'Često postavljena pitanja',
    TEXT_CARD_1: {
      TITLE: 'Velik krug provjerenih i visoko ocijenjenih instruktora',
      SUBTITLE:
        'Naši instruktori su provjereni kako bismo osigurali da imaju adekvatno iskustvo u predavanju. Uz to, ocijenjeni su od strane roditelja i učenika nakon svakog predavanja.',
    },
    TEXT_CARD_2: {
      TITLE: 'Razgovarajte sa svakim instruktorom besplatno prije rezervacije.',
      SUBTITLE:
        'Podijelite dokumente ili zadatke s instruktorom i razgovarajte putem besplatnog videopoziva kako biste osigurali da instruktor kojeg ste izabrali savršeno odgovara Vašim potrebama.',
    },
    TEXT_CARD_3: {
      TITLE: 'Naša virtualna učionica omogućava Vam ponovno gledanje predavanja.',
      SUBTITLE:
        'Naša su predavanja toliko više od videopoziva. Naša virtualna učionica sadrži digitalnu bijelu ploču, funkciju snimanja, dijeljenje zaslona i još mnogo toga.',
    },
    TEXT_CARD_4: {
      TITLE: 'Platite samo ono što iskoristite.',
      SUBTITLE:
        'Teorem Vam neće ništa naplatiti dok ne pronađete svog savršenog instruktora, porazgovarate s njim i rezervirate svoje prvo predavanje. Bez naknada za registraciju, bez pretplate, samo jednostavno plaćanje po satu. Sigurno i pouzdano plaćanje koje obuhvaća širok raspon opcija.',
    },
    REVIEW_1: {
      TEXT: 'Teorem je pomogao mom sinu popravit ocjene iz fizike! Oduševljena sam da je sve preko interneta i da ga ne moram više vozit na drugi kraj grada!',
      NAME: 'Sandra',
      OCCUPATION: 'Lukasova majka',
    },
    REVIEW_2: {
      TEXT: 'Mogućnost besplatnog videopoziva omogućila mi je da razgovaram s nekoliko instruktora prije nego što sam se odlučila za onoga koji najbolje odgovara potrebama mog djeteta. Ne mogu biti sretnija!',
      NAME: 'Renata',
      OCCUPATION: 'Mateina i Markova majka',
    },
    REVIEW_3: {
      TEXT: 'S Teoremovom virtualnom učionicom napokon mogu radit kroz interaktivne vježbe i zabavan dizajn. Jako mi se sviđa mogućnost ponovnog gledanja predavanja.',
      NAME: 'Ivan',
      OCCUPATION: 'Student ekonomije',
    },
    BUTTON_1: 'Započnite danas',
    FAQ: {
      QUESTION_1: 'Zašto 1-na-1 instrukcije?',
      ANSWER_1:
        '1-na-1 online instrukcije daju djeci mogućnost učenja brzinom koja njima najviše odgovara i načinom koji se najbolje poklapa s njihovim stilom učenja. Tinejdžeri su često presramežljivi da bi podigli ruku u razredu - posebice ako se muče s razumijevanjem. Sigurnost 1-na-1 instrukcija znači da imaju priliku postaviti sva pitanja koja žele i učiti o temama koliko im je god potrebno dok ih ne savladaju.',
      QUESTION_2: 'Koje su prednosti online instrukcija?',
      ANSWER_2:
        'Teoremove online instrukcije pomažu djeci osloboditi svoj puni potencijal. Naša digitalna bijela ploča omogućava instruktorima da objasne komplicirane koncepte na jednostavan i obazriv način. S čime god da Vaše dijete treba pomoć, naši instruktori će pronaći najbolji način za navoditi ga. Uz to, online model uklanja geografska ograničenja u pronalaženju vrhunskog instruktora.',
      QUESTION_3: 'Koje kvalifikacije imaju instruktori?',
      ANSWER_3:
        'Kanditati prolaze kroz više testova prije nego što postanu instruktori na našoj platformi. Provjerava im se znanje individualnih predmeta te osobne i nastavne vještina. Jako malen broj kandidata koji se prijave su izabrani za instruktore.',
      QUESTION_4: 'Kako funkcioniraju online predavanja?',
      ANSWER_4:
        'Imamo našu virtualnu učionicu koja uključuje videopoziv, poruke, i interaktivnu bijelu ploču što učenicima i instruktorima omogućava laku komunikaciju, diskutiranje o kompleksnim temama i zajedničko vježbanje zadataka. S videopozivima, imaju prirodni razgovor - baš kao na FaceTime-u, WhatsAppu i drugim aplikacijama koje tinejdžeri često koriste. Sve naše instrukcije traju 50 minuta.',
    },
  },
  PRICING: {
    HERO: {
      TITLE: 'Najveća vrijednost za Vaš novac',
      SUBTITLE: 'Teorem pomaže učenicima svih dobnih skupina ostvariti svoje akademske ciljeve.',
    },
    SUBTITLE_1: 'Naš proces predavanja',
    SUBTITLE_2: 'Prednosti Teorema',
    SUBTITLE_3: 'Često postavljena pitanja',
    BUTTON_1: 'Rezervirajte svoje besplatne konzultacije',
    TEXT_CARD_1: {
      TITLE: 'Koja je cijena online instrukcija?',
      SUBTITLE:
        'Teoremovi instruktori sami određuju svoje cijene, ovisno o njihovom iskustvu i dostupnosti. Cijene instrukcija kreću se od 10 EUR.',
    },
    FAQ: {
      QUESTION_1: 'Koja je cijena instruktora?',
      ANSWER_1:
        'Teoremovi instruktori sami određuju svoje cijene, ovisno o njihovom iskustvu i dostupnosti. Cijene instrukcija kreću se od 10€. Možete vidjeti sve instruktore koji odgovaraju Vašem budžetu koristeći filtriranje prema cijeni u sekciji “Pretraga instruktora”.',
      QUESTION_2: 'Kako pronaći instruktora?',
      ANSWER_2:
        'Pronaći instruktora je jednostavno. Nakon što otvorite korisnički račun na Teoremu, možete tražiti instruktore i filtrirati rezultate pretraživanja prema predmetu, razini, cijeni i dostupnosti.',
      QUESTION_3: 'Mogu li promijeniti instruktora ako nisam zadovoljan/-na?',
      ANSWER_3: 'Naravno! Slobodni ste promijeniti instruktora kada god želite, a mi rješavamo administraciju za Vas.',
      QUESTION_4: 'Mogu li dobiti povrat novca?',
      ANSWER_4: 'Da, ako otkažete do 24 sata unaprijed, izdat ćemo Vam puni povrat novca za Vaše predavanje.',
    },
    STEP_1: {
      TITLE: 'Besplatne početne konzultacije',
      SUBTITLE:
        'Tijekom prvog, besplatnog razgovora, Teoremov instruktor utvrdit će potrebe djeteta i odrediti idealan plan učenja temeljen na osobnosti učenika, pristupu učenju i ostalim potrebama.',
    },
    STEP_2: {
      TITLE: 'Probno predavanje',
      SUBTITLE: 'Probno predavanje daje Vam priliku vidjeti kako instruktor predaje i sviđa li Vam se Teorem platforma.',
    },
    STEP_3: {
      TITLE: 'Suradnja',
      SUBTITLE:
        'Teoremove instrukcije su jednostavne i bez muke. Nakon registracije, sve što trebate, pronaći ćete na našoj platformi. Možete nadgledati napredak, mijenjati vrijeme instrukcija, rezervirati nove instrukcije, otkazati ih ili kontaktirati instruktora, sve u samo nekoliko klikova.',
    },
    CARD_1: {
      TITLE: 'Fleksibilnost',
      SUBTITLE: 'Jednostavno i fleksibilno zakazivanje prilagođeno Vama.',
    },
    CARD_2: {
      TITLE: 'Virtualna učionica',
      SUBTITLE: 'Dostupno na svim uređajima, s ugrađenom funkcijom snimanja, digitalnom bijelom pločom te dijeljenjem ekrana i dokumenata.',
    },
    CARD_3: {
      TITLE: 'Sigurno plaćanje',
      SUBTITLE:
        'Sigurno i pouzdano plaćanje u suradnji sa Stripe-om. Bez skrivenih naknada, plaćaju se samo satovi instrukcija, a moguć je i povrat novca.',
    },
    CARD_4: {
      TITLE: 'Podrška',
      SUBTITLE: '24/7 pristup podršci. Naš posvećeni tim podrške dostupan Vam je u svakom trenutku.',
    },
  },
  BECOME_A_TUTOR: {
    HERO: {
      TITLE: 'Počnite podučavati online',
      SUBTITLE: 'Držite instrukcije kada god Vi to želite, iz udobnosti vlastitog doma.',
    },
    TEXT_CARD_1: {
      TITLE: 'Teoremove online instrukcije olakšavaju Vaš život',
      SUBTITLE: 'Pružit ćemo Vam sve što trebate za podučavanje online. Pomažemo Vam pronaći učenike i upravljati rasporedom.',
    },
    TEXT_CARD_2: {
      TITLE: 'Odredite svoju cijenu i dostupnost!',
      SUBTITLE: 'Koristite naš alat kako biste pratili koliko zarađujete i povećali bazu Vaših klijenata!',
    },
    REVIEW_1: {
      TEXT: 'Uz Teorem sam si stvorio stabilan mjesečni prihod i ne moram se više toliko brinut o svojim troškovima!',
      NAME: 'Ante',
      OCCUPATION: 'Instruktor fizike',
    },
    REVIEW_2: {
      TEXT: 'Zbog Teorema sam se uspjela preselit na Vis i nastavit davat instrukcije učenicima u Zagrebu!',
      NAME: 'Dunja',
      OCCUPATION: 'Instruktorica njemačkog',
    },
    SUBTITLE_1: 'Ovdje smo da Vam pomognemo napredovati!',
    SUBTITLE_2: 'Kako postati online instruktor na Teoremu',
    SUBTITLE_3: 'Često postavljena pitanja',
    BUTTON_1: 'Započnite danas',
    FAQ: {
      QUESTION_1: 'Kako postati online instruktor?',
      ANSWER_1:
        'Ako Vas zanima kako da započnete s davanjem instrukcija, došli ste na pravo mjesto! Samo stvorite korisnički račun, rezervirajte svoj intervju i mi ćemo Vam pomoći započeti!',
      QUESTION_2: 'Koji su zahtjevi za instruktore?',
      ANSWER_2:
        'Obično zahtijevamo da se naši instruktori odluče za najmanje 6 predavanja tjedno. Najniža razina kvalifikacije koju instruktori moraju imati je srednja škola. Instruktori moraju imati računalo i stabilnu internetsku vezu kako bi predavali online.',
      QUESTION_3: 'Koliko je dugo potrebno čekati kako bih započeo/la s održavanjem online predavanja?',
      ANSWER_3:
        'Budući da se registracijski proces sastoji od nekoliko jednostavnih koraka, obično treba samo 1 dan kako bi se izvršila verifikacija i krenulo s online predavanjem.',
      QUESTION_4: 'Kako izgleda radni odnos?',
      ANSWER_4:
        'Vi nudite nezavisne instrukcije koristeći Teorem platformu. Nema radnog odnosa te ste sami odgovorni za sve porezne kontribucije.',
      QUESTION_5: 'Što razlikuje Teorem od ostalih platformi?',
      ANSWER_5:
        'Mi Vam garantiramo stabilan dotok novih učenika te se brinemo o svemu tako da se Vi možete u potpunosti posvetiti predavanjima.',
      QUESTION_6: 'Kako Teorem zarađuje?',
      ANSWER_6: 'Teorem uzima proviziju od 15% od instruktora.',
    },
    CARD_1: {
      TITLE: '"Remote" rad',
      SUBTITLE: 'Sve što Vam treba su računalo i internet',
    },
    CARD_2: {
      TITLE: 'Alat za Vas',
      SUBTITLE: 'Sustav rezervacija, chat, videopozivi i više.',
    },
    CARD_3: {
      TITLE: 'Stabilan prihod',
      SUBTITLE: 'Mi osiguravamo da budete plaćeni.',
    },
    CARD_4: {
      TITLE: 'Odredite svoju cijenu',
      SUBTITLE: 'Nema ograničenja u pogledu onoga što možete zaraditi.',
    },
    CARD_5: {
      TITLE: 'Podrška 24/7',
      SUBTITLE: 'Mi brinemo da svi Vaši problemi budu riješeni.',
    },
    CARD_6: {
      TITLE: 'Poboljšajte svoje podučavanje',
      SUBTITLE: 'Mi brinemo o administraciji, a Vi se samo fokusirajte na predavanje.',
    },
    STEP_1: 'Stvorite svoj korisnički račun i ispunite podatke.',
    STEP_2: 'Učitajte kratak video da dokažete svoj identitet.',
    STEP_3: 'Dovršite svoj profil i počnite podučavati!',
  },
  TOKEN_NOT_VALID: {
    TITLE: 'Neispravan token!',
    DESCRIPTION: 'Molimo provjerite svoj sandučiċ i pokušajte ponovno',
    HINT: '(Ako ste već zatražili novi aktivacijski e-mail, provjerite jeste li koristili najnoviji link, odnosno zadnji e-mail koji ste primili.)',
    BUTTON: 'Prijava',
  },
  STRIPE_CONNECTED: {
    WELCOME: 'Uspjeh!',
    DESCRIPTION: 'Stripe je uspješno povezan.',
    BUTTON: 'Nastavi',
  },
  STRIPE_FAIL: {
    WELCOME: 'Nešto je pošlo po krivu.',
    DESCRIPTION: 'Stripe nije uspješno povezan. Molimo pokušajte ponovno.',
    BUTTON: 'Nastavi',
  },
  STRIPE_CONNECT: {
    TITLE: 'Isplate',
    ADDRESS: 'Adresa',
    LINE_1: 'Adresa linija 1',
    LINE_2: 'Adresa linija 2',
    POST_CODE: 'Poštanski broj',
    STATE: 'Država',
    LAST_4_SSN: 'Zadnje 4 znamenke SSN-a',
    CITY: 'Grad',
    ROUTING_NUMBER: 'Broj usmjeravanja',
    ACCOUNT_NUMBER: 'Broj računa',
    IBAN: 'IBAN',
    IBAN_CONFIRM: 'IBAN',
    SAVE: 'Spremi',
    CANCEL: 'Odustani',
    TERMS: '<div>Klikom na gumb slažete se s <a href="https://stripe.com/legal/connect-account" target="_blank">Uvjetima pružanja usluge</a>.</div>',

    SUCCESS: 'Račun za plaćanje povezan',
  },
  SUBJECTS: {
    biology: 'Biologija',
    chemistry: 'Kemija',
    economics: 'Ekonomija',
    english: 'Engleski jezik',
    croatian: 'Hrvatski jezik',
    geography: 'Geografija',
    german: 'Njemački jezik',
    history: 'Povijest',
    it: 'IT',
    italian: 'Talijanski jezik',
    maths: 'Matematika',
    music: 'Glazbena kultura',
    physics: 'Fizika',
    polish: 'Poljski jezik',
    psychology: 'Psihologija',
    religion: 'Vjeronauk',
    russian: 'Ruski jezik',
    sociology: 'Sociologija',
    spanish: 'Španjolski jezik',
    businessstudies: 'Poslovne studije',
    classicalgreek: 'Klasični grčki jezik',
    computing: 'Računarstvo',
    designandtechnology: 'Dizajn i tehnologija',
    drama: 'Drama',
    environmentalstudies: 'Zaštita okoliša',
    french: 'Francuski jezik',
    governmentandpolitics: 'Politika i gospodarstvo',
    latin: 'Latinski jezik',
    science: 'Znanost',
    art: 'Umjetnost',
    accounting: 'Računovodstvo',
    architecture: 'Arhitektura',
    chemicalengineering: 'Kemijsko inženjerstvo',
    law: 'Pravo',
    medicine: 'Medicina',
    philosophy: 'Filozofija',
    politics: 'Politika',
    statistics: 'Statistika',
    calculus: 'Matematička analiza 2',
    economy: 'Ekonomija',
    reading: 'Čitanje',
    writing: 'Pisanje',
    precalculus: 'Matematička analiza 1',
  },
  SUBJECTS_GENITIVE: {
    biology: 'Biologije',
    chemistry: 'Kemije',
    economics: 'Ekonomije',
    english: 'Engleskog jezika',
    croatian: 'Hrvatskog jezika',
    geography: 'Geografije',
    german: 'Njemačkog jezika',
    history: 'Povijesti',
    it: 'IT-ja',
    italian: 'Talijanskog jezika',
    maths: 'Matematike',
    music: 'Glazbene kulture',
    physics: 'Fizike',
    polish: 'Poljskog jezika',
    psychology: 'Psihologije',
    religion: 'Vjeronauka',
    russian: 'Ruskog jezika',
    sociology: 'Sociologije',
    spanish: 'Španjolskog jezika',
    businessstudies: 'Poslovnih studija',
    classicalgreek: 'Klasičnog grčkog jezika',
    computing: 'Računarstva',
    designandtechnology: 'Dizajna i tehnologije',
    drama: 'Drame',
    environmentalstudies: 'Zaštite okoliša',
    french: 'Francuskog jezika',
    governmentandpolitics: 'Politike i gospodarstva',
    latin: 'Latinskog jezika',
    science: 'Znanosti',
    art: 'Umjetnosti',
    accounting: 'Računovodstva',
    architecture: 'Arhitekture',
    chemicalengineering: 'Kemijskog inženjerstva',
    law: 'Prava',
    medicine: 'Medicine',
    philosophy: 'Filozofije',
    politics: 'Politike',
    statistics: 'Statistike',
    calculus: 'Matematičke analize 2',
    economy: 'Ekonomije',
    reading: 'Čitanja',
    writing: 'Pisanja',
    precalculus: 'Matematičke analize 1',
  },
  SEO_TITLE: {
    TUTOR_SEARCH: 'Online instruktori i učitelji :subject za privatne instrukcije :subject',
    TUTOR_PROFILE: ' | Uči s instruktorima ',
  },
  LEVELS: {
    primaryschool: 'Osnovna škola',
    highschool: 'Srednja škola',
    ib: 'IB',
    maturaprep: 'Priprema za maturu',
    university: 'Fakultet',
    "elementary": "Osnovna škola",
    "middle": "Srednja škola",
    "high": "Srednja škola",
    "ap": "Za napredne",
    "sat": "Priprema za maturu",
  },
  ROLES: {
    student: 'Učenik',
    parent: 'Roditelj',
    child: 'Dijete',
    tutor: 'Instruktor',
    admin: 'Administrator',
  },
  CONSTANTS: {
    MONTHS_SHORT: {
      JAN: 'SIJ',
      FEB: 'VELJ',
      MAR: 'OŽU',
      APR: 'TRA',
      MAY: 'SVI',
      JUN: 'LIP',
      JUL: 'SRP',
      AUG: 'KOL',
      SEP: 'RUJ',
      OCT: 'LIS',
      NOV: 'STU',
      DEC: 'PRO',
    },
    MONTHS_LONG: {
      JAN: 'Siječanj',
      FEB: 'Veljača',
      MAR: 'Ožujak',
      APR: 'Travanj',
      MAY: 'Svibanj',
      JUN: 'Lipanj',
      JUL: 'Srpanj',
      AUG: 'Kolovoz',
      SEP: 'Rujan',
      OCT: 'Listopad',
      NOV: 'Studeni',
      DEC: 'Prosinac',
    },
    DAYS_SHORT: {
      MON: 'Pon',
      TUE: 'Uto',
      WED: 'Sri',
      THU: 'Čet',
      FRI: 'Pet',
      SAT: 'Sub',
      SUN: 'Ned',
    },
    DAYS_LONG: {
      MON: 'Ponedjeljak',
      TUE: 'Utorak',
      WED: 'Srijeda',
      THU: 'Četvrtak',
      FRI: 'Petak',
      SAT: 'Subota',
      SUN: 'Nedjelja',
    },
  },
  PATHS: {
    ADD_CHILD: '/hr/dodaj-dijete',
    FORGOT_PASSWORD: '/hr/zaboravljena-lozinka',
    RESET_PASSWORD: '/hr/reset-password',
    MY_BOOKINGS: '/hr/kalendar',
    SEARCH_TUTORS: '/hr/pretraga-instruktora',
    SEARCH_TUTORS_TUTOR_PROFILE: '/hr/profil/:tutorSlug',
    STUDENT_PROFILE: '/hr/student-profil/:userId',
    PROFILE: '/hr/pretraga-instruktora/profil/:tutorSlug',
    SEARCH_TUTORS_TUTOR_BOOKINGS: '/hr/pretraga-instruktora/kalendar/:tutorSlug',
    ONBOARDING: '/hr/onboarding',
    MY_REVIEWS: '/hr/moje-recenzije',
    COMPLETED_LESSONS: '/hr/zavrsene-lekcije',
    CHAT: '/hr/razgovor',
    DASHBOARD: '/hr/dashboard',
    NOTIFICATIONS: '/hr/glavna-ploca/obavijesti',
    EARNINGS: '/hr/prihod',
    TERMS: '/hr/uvjeti-koristenja',
    PRIVACY: '/hr/politika-privatnosti',
    TUTOR_MANAGMENT: '/hr/upravljanje-instruktorima',
    STUDENT_MANAGEMENT: '/hr/upravljanje-studentima',
    BOOKING_MANAGEMENT: '/hr/sve-rezervacije',
    TUTOR_MANAGMENT_TUTOR_PROFILE: '/hr/upravljanje-instruktorima/profil/:tutorSlug',
    LANDING_PATHS: {
      HOW_IT_WORKS: '/hr/online-instrukcije',
      BECOME_TUTOR: '/hr/postani-instruktor',
      PRICING: '/hr/cijene',
      TERMS: '/hr/uvjeti-koristenja',
      PRIVACY: '/hr/politika-privatnosti',
    },
    PROFILE_PATHS: {
      MY_PROFILE: '/hr/moj-profil',
      MY_PROFILE_INFO: '/hr/moj-profil/informacije',
      MY_PROFILE_INFO_PERSONAL: '/hr/moj-profil/informacije/postavke-profila',
      MY_PROFILE_INFO_AVAILABILITY: '/hr/moj-profil/informacije/dostupnost',
      MY_PROFILE_INFO_TEACHINGS: '/hr/moj-profil/informacije/predmeti',
      MY_PROFILE_INFO_ADDITIONAL: '/hr/moj-profil/informacije/o-meni',
      MY_PROFILE_ACCOUNT: '/hr/moj-profil/korisnicki-racun',
      MY_PROFILE_CHILD_INFO: '/hr/moj-profil/djeca',
    },
    TOKEN_NOT_VALID: '/hr/invalid-token',
    STRIPE_CONNECTED: '/hr/racun-povezan',
    STRIPE_FAIL: '/hr/racun-pogreska',
    RESEND_ACTIVATION_TOKEN: '/hr/ponovna-aktivacija',
    TUTOR_VIDEOS: '/hr/tutor-video',
  },
  SEO: {
    FALLBACK: {
      TITLE: 'Teorem',
      META: 'Teorem nudi 1-na-1 online instrukcije, pomoć s učenjem, pomoć sa zadaćom, pripremanje za ispite, i virtualno učenje za sve učenike. - Savršen instruktor za bilokoga - Plan učenja stvoren za Vas - Virtualna učionica - Započni danas',
    },
    HOW_IT_WORKS: {
      TITLE: 'Najbolje 1-na-1 online instrukcije i pomoć s učenjem | Teorem',
      META: 'Teorem nudi 1-na-1 online instrukcije, pomoć s učenjem, pomoć sa zadaćom, pripremanje za ispite, i virtualno učenje za sve učenike. - Savršen instruktor za bilokoga - Plan učenja stvoren za Vas - Virtualna učionica - Započni danas',
    },
    PRICING: {
      TITLE: 'Cijene visokokvalitetnih online instrukcija | Teorem',
      META: 'Cijene za 1-na-1 50-minutne instrukcije počinju od 10€, s prosječnom cijenom od 12€, ovisno o instruktoru i planu učenja. Saznajte više o cijenama tako da kontaktirate naše instruktore. ',
    },
    BECOME_TUTOR: {
      TITLE: 'Postani online instruktor | Teorem',
      META: 'Kako postati online instruktor na Teoremu - 1. Registriraj se da postaneš instruktor - 2. Završi kviz o svom predmetu - 3. Dokaži svoj identitet - 4. Započni davat instrukcije',
    },
    TERMS: {
      TITLE: 'Uvjeti korištenja | Teorem',
      META: 'Dobrodošli na Teorem! Ovi uvjeti korištenja definiraju pravila korištenja Teoremove webstranice.',
    },
    PRIVACY: {
      TITLE: 'Politika privatnosti | Teorem',
      META: 'Jedan od naših glavnih prioriteta u Teoremu je privatnost naših posjetitelja. Ova Politika privatnosti sadrži tipove podataka koji su skupljani i spremani od strane Teorema, te na koji ih način koristimo.',
    },
  },
  LANGUAGE_MODAL: {
    WELCOME: 'Dobrodošli na Teorem!',
    ABOUT: 'Molimo odaberite Vašu lokaciju.',
  },
  TOKEN_EXPIRED: {
    RESEND_ACTIVATION_MESSAGE: 'RESEND_ACTIVATION_MESSAGE',
    WELCOME: 'WELCOME',
    DESCRIPTION: 'DESCRIPTION',
  },
  BOOKING: {
    SUCCESS: 'Rezervacija uspješna!',
    FAILURE: 'Rezervacija neuspješna!',
    TOAST_CANT_BOOK: 'Ne možete rezervirati lekciju u odabrano vrijeme!',
    CANT_BOOK_MESSAGE: 'Molimo rezervirajte minimalno 3 sata unaprijed',
  },
  TUTOR_INTRO: {
    MODAL: {
      TITLE: 'Želite li proći tutorijal?',
      BODY: 'Tutorijal će vas ukratko upoznati s osnovnim funkcionalnostima Teorema',
      BUTTON_SKIP: 'Preskoči',
      BUTTON_START: 'Pokreni',
    },
    DASHBOARD: {
      STEP1: {
        TITLE: 'Zahtjevi za predavanja',
        BODY: 'Ovdje možete vidjeti i upravljati svojim zahtjevima za instrukcije.',
      },
      STEP2: {
        TITLE: 'Pridruživanje instrukcijama',
        BODY: 'Instrukcijama se možete pridružiti izravno iz kartice "Današnji raspored" (također se možete pridružiti putem svog kalendara). Nakon što kliknete \'Završi\', automatski će se pokrenuti testni sastanak gdje možete isprobati ploču!',
      },
    },
    BUTTON_NEXT: 'Sljedeći',
    BUTTON_PREVIOUS: 'Prethodni',
    BUTTON_FINISH: 'Završi',
    BUTTON_RESTART: 'Pokreni tutorijal',
  },
  STUDENT_INTRO: {
    DASHBOARD: {
      STEP1: {
        TITLE: 'Današnji raspored',
        BODY: 'Ovdje ćete vidjeti predavanja koja imate danas i moći ćete se spojiti na predavanja. (Predavanja također možete vidjeti na kalendaru)',
      },
      STEP2: {
        TITLE: 'Nepročitane poruke',
        BODY: 'Ovdje ćete vidjeti sve poruke koje vam istruktor šalje. (Isto to možete vidjeti na kartici \'Razgovori\')',
      },
      STEP3: {
        TITLE: 'Preporuke instruktora',
        BODY: 'Ovdje se nalaze tri instruktora koji najviše odgovaraju vašim potrebama (ovisno o onome što ste odabrali pri registraciji). Koristecći filtere možete odabrati predmet, razinu ili vrijeme kada je instruktor dostupan.',
      },
    },
    BUTTON_NEXT: 'Next',
    BUTTON_PREVIOUS: 'Previous',
    BUTTON_FINISH: 'Finish',
    BUTTON_RESTART: 'Restart tutorial',
  },
  BOOKING_POPUP: {
    TITLE: 'Plaćanje uspješno',
    DESCRIPTION:
      'Vaš instruktor sada ima 24 sata da prihvati Vašu rezervaciju. Za najbolje iskustvo učenja, molimo podijelite uvide u gradivo na koje se želite usredotočiti.',
    CURRICULUM_PLACEHOLDER: 'S kojim školskim gradivom trebate pomoć?',
    TEXTBOOK_PLACEHOLDER: 'Koji se udžbenik koristi?',
    GRADE_PLACEHOLDER: 'Trenutna ocjena iz predmeta',
    NOTES_PLACEHOLDER: 'Napomene ili posebni zahtjevi',
    BUTTON: {
      COMPLETE: 'Završi',
      SKIP: 'Preskoči za sad',
    },
  },
  CREDITS: {
    TITLE: 'Krediti',
  },
  RESCHEDULE: {
    ACCEPT: 'Izmjena prihvaćena',
    DENIED: 'Izmjena odbijena',
  },
  CHECKOUT: {
    PRICE: 'Cijena:',
    DISCOUNT: 'Popust:',
    TOTAL: 'Ukupno za platiti:',
    AVAILABLE_CREDITS: 'Dostupni krediti:',
    NEW_CREDITS_BALANCE: 'Novo stanje kredita:',
  },

  ID_VERIFICATION: {
    ID_SECTION: 'Dokaz o identitetu',
    ID_SECTION_DESCRIPTION: 'U svrhu sprječavanja pranja novca i financiranja terorizma, molimo potvrdite Vaš identitet.',
    BANNER: 'OBAVIJEST: U svrhu sprječavanja pranja novca i financiranja terorizma, molimo potvrdite Vaš identitet kako bi nastavili zaprimati isplate.',
    ACCOUNT_VERIFIED: 'Hvala! Vaš račun je verificiran.',
    DOCUMENTS_PROVIDED: 'Hvala! Predali ste dokumente, čeka se verifikacija.',
    DISABLED_PAYOUTS: {
      PART_1: 'Vaše isplate su obustavljene. Molimo potvrdite Vaš identitet kako biste nastavili zaprimati isplate.',
      SOON: 'Rok predaje: uskoro',
    },
    DAYS_REMAINING: {
      P_1: 'Imate još ',
      P_2: ' dan/a prije nego što Vaše isplate budu obustavljene. Molimo potvrdite Vaš identitet kako biste izbjegli obustavu isplata.',
    },
    FORM: {
      FRONT: 'Prednja strana osobne iskaznice',
      BACK: 'Stražnja strana osobne iskaznice',
      TIP_1: 'Slika mora biti u boji',
      TIP_2: 'Na dokumentu mora pisati ime koje ste koristili pri registraciji',
      SUBMIT: 'Pošalji',
    },
  },

  VIDEO_PREVIEW: {
    TITLE: 'Video pregled',
    NOT_APPROVED: 'Administrator još nije odobrio vaš video (neće se prikazivati na vašem profilu)',
    TRANSCODING: 'Vaš video se trenutno obrađuje. Obično traje nekoliko minuta...',
    RECORD_VIDEO: 'Snimi video',
    OR: 'ILI',
    UPLOAD_VIDEO: 'Učitaj video',
    START: 'Započni',
    DELETE: 'Obriši',

    RECORD_MODAL: {
      SETTINGS: 'Audio i video postavke',
      CAMERA: 'Kamera',
      MICROPHONE: 'Mikrofon',
      START: 'Pokreni snimanje',
      STOP: 'Zaustavi snimanje',
      RESTART: 'Ponovno snimi',
      UPLOAD: 'Učitaj',
      REMAINING_TIME: {
        PART_1: 'Snimka će se automatski zaustaviti za ',
        PART_2: ' s',
      },
      RECORDING_TIME: 'Vrijeme snimanja:',
      PERMISSIONS_ERROR_MESSAGE:
        'Niste dozvolili korištenje kamere i mikrofona. Morat ćete dozvoliti kako bi mogli snimiti video, a i održavati instukcije',
    },
    LOADING: {
      PREPARING: 'Priprema prijenosa...',
      UPLOADING: 'Prijenos',
      SUCCESS: 'Uspješno ste učitali video',
    },
    CONFIRMATION: {
      TITLE: 'Jeste li sigurni da želite obrisati video?',
      DESCRIPTION: 'Ovime će vaš video biti obrisan i morat ćete snimiti novi!',
      CONFIRM: 'Obriši',
      CANCEL: 'Odustani',
    },
    FILE_UPLOAD: {
      FORMAT: 'MP4 format',
      SIZE_MESSAGE: 'Maksimalna veličina datoteke je 10MB!',
      DURATION_MESSAGE: 'Maksimalna duljina videa je 2 minute!',
    },
    TIPS: {
      DO: {
        TITLE: 'Što učiniti:',
        LIST: {
          TIP_1: 'Između 30 sekundi i 2 minute',
          TIP_2: 'Snimajte horizontalno i u razini očiju',
          TIP_3: 'Koristite dobro osvjetljenje i neutralnu pozadinu',
          TIP_4: 'Koristite stabilnu podlogu, kako video ne bi bio mutan',
          TIP_5: 'Pobrinite se da se vidi cijelo lice i oči',
          TIP_6: 'Istaknite svoje iskustvo u podučavanju',
          TIP_7: 'Srdačno pozdravite svoje učenike i pozovite ih da rezerviraju sat',
        },
      },
      DONT: {
        TITLE: 'Nemojte:',
        LIST: {
          TIP_1: 'Navoditi svoje prezime ili bilo kakve kontakt podatke',
          TIP_2: 'Uključivati logo ili linkove',
          TIP_3: 'Koristiti dijaprojekcije ili prezentacije',
          TIP_4: 'Imati druge osobe vidljive u vašem videu',
        },
      },
    },
  },
  COUNTRY: {
    PLACEHOLDER: 'Država',
    HR: 'Hrvatska',
    US: 'SAD',
  },
  BANNER: {
    REVIEW: {
      PART_1: 'Nedavno ste imali lekciju iz predmeta ',
      PART_2: 's instruktorom ',
      PART_3: '. Odvojite trenutak da podijelite svoje iskustvo ostavljajući recenziju.',
      TOOLTIP: 'Ostavljanje recenzije pomaže ostalima. Stvara učinkovito i transparentno okruženje.',
    },
    ADD_CHILD: {
      BUTTON: 'Dodaj dijete',
    },
    VIDEO_PREVIEW: {
      TEXT: 'Dodajte video na svoj profil kako biste povećali svoju vidljivost roditeljima. Od 30. lipnja 2024. videozapisi postat će obavezni kako biste zadržali svoj profil instruktora.',
      BUTTON: 'Dodaj video',
    },
  },
};
